import * as React from "react";

/**
 * useFetch is a wrapper that I create with the motivation
 * to reduce redundant setLoading code when trying todo
 * some API calls
 */
export const useFetch = (fetchFn) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchFn();
        if (isMounted && result) {
          setData(result);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setError(error);
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchFn]);

  return { isLoading, data, error };
};
