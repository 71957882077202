const webviewChecker = () => {
  if (typeof window === "undefined") {
    return false;
  }

  let navigator = window.navigator;

  const standalone = navigator.standalone;
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios ? !standalone && !safari : userAgent.includes("wv");
};

export default webviewChecker;
