import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  direction: "rtl",
  fonts: {
    body: `'Readex Pro', sans-serif`,
  },
  colors: {
    customLightGray: "#D9D9D9",
    customDarkerRed: "#BC102E",
    customRed: "#FD0200",
    brandRed: {
      500: "#D32E2E",
      600: "#D32E2E",
    },
    disabledInput: "#F7F7F8",
    inputBox: "#F5F5F5",
    text: "#737373",
    formError: "#DC5858",
    link: "#E8BA52",
    naturalGray: "#63647A",
    pinBorderDefault: "#C1C1CA",
    brandSuccess: "#4B9B70",
    pinBorderFailed: "#e95640",
    secondaryGray: "#6C7181",
    naturalBlack: "#0A0A0C",
    packageGray: "#9C9C9C",
    packageRed: "#D80102",
    borderGrey: "#C5C5C5",
    accordionBorder: "#E0E0E4",
    buttonRedText: "#E34047",
    borderGreySecondary: "#E7E7EA",
    secondaryBlack: "#3B4157",
    secondaryBlue: "#2A345E",
    gold: "#EDA43C",
    yellow: "#FAEC4F",
    yellowLink: "#EDC875",
    trialTitleBlack: "#0A122D",
    trialBlack: "#010204",
    trialBlackBorder: "rgba(4, 7, 18, 0.3)",
    beige: "rgba(255, 130, 14, 0.1)",
    orange: "#FF820E",
    taxContainerGray: "#EFF0F2",
    taxTextGray: "#828395",
    checkboxGray: "#BEBEBE",
    textAreaGray: "#FBFBFB",
    textAreaBorder: "#E7E7E7",
    cancelSubGray: "#AEAEAE",
    cancelSubButtonGray: "#A8A8A8",
  },
  fontSizes: {
    xxs: "0.675rem",
  },
  space: {
    11: "2.75rem",
  },
  components: {
    Link: {
      baseStyle: {
        textDecoration: "underline",
        color: "link",
      },
    },
    PinInput: {
      baseStyle: {
        borderRadius: "md",
      },
      sizes: {
        xl: {
          width: "4.1rem",
          height: "4rem",
          fontSize: "2xl",
        },
      },
    },
    Alert: {
      baseStyle: {
        description: {
          whiteSpace: "pre-line",
        },
      },
    },
    Accordion: {
      baseStyle: {
        container: {
          borderTopWidth: "none",
          _last: {
            borderBottomWidth: "none",
          },
          borderWidth: "1px",
          borderColor: "accordionBorder",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          border: "1px solid",
          borderColor: "checkboxGray",
        },
      },
    },
  },
  styles: {
    global: {
      "#chakra-toast-manager-bottom": {
        bottom: "20px !important",
      },
    },
  },
});

export default theme;
