import * as rudderanalytics from "rudder-sdk-js";

export async function rudderInitialize() {
  rudderanalytics.load(
    `${process.env.REACT_APP_WRITE_KEY}`,
    `${process.env.REACT_APP_DATA_PLANE_URL}`,
    {
      integrations: { All: true }, // load call options
    }
  );

  rudderanalytics.ready(() => {
    console.log("All set!");
  });
}
