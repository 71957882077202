import axios from "axios";

const BASE_URL = process.env.REACT_APP_IS_VERSION_V5 === 'true' ? `${process.env.REACT_APP_TAP_BASE_URL}/webview` : process.env.REACT_APP_TAP_BASE_URL;

const cancelSubscription = async (payload) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_TAP_BASE_URL}/tap/cancel-recurring`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getUserSubscription = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/tap/user-subscription`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const chargeCard = async (payload) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_TAP_BASE_URL}/tap/charge-card`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const sendSubRequest = async (payload) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_TAP_BASE_URL}/tap/subscribe`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getCards = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/tap/get-user-cards`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const updateCard = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/tap/update-user-card`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const deleteCard = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/tap/delete-user-card`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getPackages = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/tap/get-subscription-types`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const applyCoupon = async (payload) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_TAP_BASE_URL}/tap/coupon/apply`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getWhitelistUser = async (payload) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_TAP_BASE_URL}/tap/whitelist`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export {
  cancelSubscription,
  getUserSubscription,
  chargeCard,
  sendSubRequest,
  getCards,
  updateCard,
  deleteCard,
  getPackages,
  applyCoupon,
  getWhitelistUser
};

