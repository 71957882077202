import { Box, Image } from "@chakra-ui/react";

const Spinner = () => {
  return (
    <Box
      zIndex="banner"
      alignItems="center"
      justifyContent="center"
      display="flex"
      width="full"
      height="full"
      top="0"
      left="0"
      position="fixed"
      backgroundColor="blackAlpha.400"
    >
      <Box width="20" height="20">
        <Image width="full" height="full" src="/animations/spinner.svg" />
      </Box>
    </Box>
  );
};

export default Spinner;
