/**
 * TODO: move all service to this file
 */

import baseAxios from "axios";

let axios = baseAxios;

if(process.env?.REACT_APP_COUNTRY){
  axios = baseAxios.create({
    headers: {
      'cf-ipcountry': process.env.REACT_APP_COUNTRY,
      'x-debug-country': process.env.REACT_APP_COUNTRY
    }
  })
}

const getPackages = async (payload) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_V5_BASE_URL}/mondia/get-subscription-type`,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getOperators = async (payload) => {
  try {
    const { operator, ...remainingPayload } = payload;

    const res = await axios.post(
      `${process.env.REACT_APP_V5_BASE_URL}/mondia/get-subscription-type/v3`,
      remainingPayload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getCustomerDetails = async (payload) => {
  try {
    const BASE_URL = process.env.REACT_APP_IS_VERSION_V5 === 'true' ? `${process.env.REACT_APP_V5_BASE_URL}/UserProfile/get_user_profile/v2` : `${process.env.REACT_APP_V5_BASE_URL}/mondia/get-user-profile`;
    const res = await axios.post(
      BASE_URL,
      payload
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getCountryCode = async () => {
  try {

    const res = await axios.post(`${process.env.REACT_APP_V5_BASE_URL}/mondia/get-country-code`);

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getLastNotification = async (payload, elapsedTime = 0) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_V5_BASE_URL}/mondia/notification-by-purchase-token`,
      payload
    );

    const responseData = response.data;

    const cancelledStatus = responseData?.data?.status === 'CANCELED' && responseData?.data?.subStatus !== 'PENDING';
    const activeStatus = responseData?.data?.status === 'ACTIVE' && responseData?.data?.isProcessed;
    
    if (cancelledStatus || activeStatus) {
      return responseData; // Return immediately if the response is as expected
    } else {
      // If the response is not as expected, wait for 5 seconds and retry
      const retryInterval = 5000; // 5 seconds
      const maxRetryTime = 3 * 60 * 1000; // 3 minutes in milliseconds

      if (elapsedTime + retryInterval > maxRetryTime) {
        // Return false if the elapsed time exceeds the limit
        return responseData;
      }

      await new Promise((resolve) => setTimeout(resolve, retryInterval));
      
      // Recursive call with updated elapsed time
      return getLastNotification(payload, elapsedTime + retryInterval);
    }
  } catch (error) {
    if (error.response.status === 404) {
      // If the response is not as expected, wait for 5 seconds and retry
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return getLastNotification(payload);
    }
    throw error;
  }
};

export { getPackages, getOperators, getCustomerDetails, getCountryCode, getLastNotification };
