import { isNil, isEmpty } from "lodash";

const isBlank = (val) => isNil(val) || isEmpty(val);

const getMondiaRenderCondition = ({
  customerDetails,
  countryCode,
  packageDetails,
  channelName,
  operator,
}) => {
  if (isBlank(operator)) {
    return (
      !isBlank(customerDetails) &&
      !isBlank(countryCode) &&
      !isBlank(packageDetails) &&
      channelName?.toLowerCase() === "mondia"
    );
  }

  return (
    !isBlank(customerDetails) &&
    !isBlank(countryCode) &&
    channelName?.toLowerCase() === "mondia"
  );
};

export default getMondiaRenderCondition;
