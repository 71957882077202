/**
 * MSW required us to setup graphql protocol in order
 * to mock graphql request
 */
import { ApolloClient, InMemoryCache } from "@apollo/client";

const clientProps = {
  uri: "http://localhost:4000",
  cache: new InMemoryCache(),
};

export const client = new ApolloClient(clientProps);
