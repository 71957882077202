import * as React from "react";

import {
  Alert,
  Box,
  AlertTitle,
  AlertDescription,
  Image,
} from "@chakra-ui/react";

import StgoIcon from "../icons/stgo-round.png";

const Toast = ({ message, status = "error" }) => {
  return (
    <Alert
      boxShadow="lg"
      borderWidth="1px"
      backgroundColor="white"
      borderRadius="3xl"
      status={status}
    >
      <Box>
        <AlertTitle>{message.title}</AlertTitle>
        <AlertDescription>{message.description}</AlertDescription>
      </Box>
      <Box minW="14" maxW="32" height="14">
        <Image width="full" height="full" src={StgoIcon} />
      </Box>
    </Alert>
  );
};

export default Toast;
