import { useCallback } from "react";

import { useToast as useChakraToast } from "@chakra-ui/react";

import Toast from "../components/toast";
/**
 * In order to render to mimic STGO error toast,
 * we need to adjust Chakra toast like this.
 */
export const useToast = () => {
  const toast = useChakraToast();

  const showToast = useCallback(
    ({ id, message, duration = 3500, status }) => {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration,
          isClosable: true,
          position: "bottom",
          render: () => (
            <Toast // Custom Toast
              message={message}
              status={status}
            />
          ),
        });
      }
    },
    [toast]
  );

  return { showToast };
};
