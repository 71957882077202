import { isNil, isEmpty } from "lodash";

const isBlank = (val) => isNil(val) || isEmpty(val);

const getTapRenderCondition = ({
  customerDetails,
  packageDetails,
  channelName,
  packageId,
}) => {
  if (!isBlank(packageId)) {
    return (
      !isBlank(customerDetails) &&
      !isBlank(packageDetails) &&
      channelName?.toLowerCase() === "tap"
    );
  }

  return !isBlank(customerDetails) && channelName?.toLowerCase() === "tap";
};

export default getTapRenderCondition;
