const getDialCode = (countryCode) => {
  const dialCode = {
    AE: "+971",
    BH: "+973",
    DZ: "+213",
    EG: "+20",
    IQ: "+964",
    JO: "+962",
    KW: "+965",
    LB: "+961",
    LY: "+218",
    MA: "+212",
    MR: "+222",
    OM: "+968",
    PS: "+970",
    QA: "+974",
    SA: "+966",
    SD: "+249",
    SO: "+252",
    SY: "+963",
    TN: "+216",
    YE: "+967",
  };

  return dialCode[countryCode];
};

export default getDialCode;
