import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enErrors from "./locales/en/translations/errors.json";
import arErrors from "./locales/ar/translations/errors.json";

const resources = {
  en: {
    errors: {
      ...enErrors,
    },
  },
  ar: {
    errors: {
      ...arErrors,
    },
  },
};

i18n.use(initReactI18next).init({
  lng: "ar",
  fallbackLng: "en",
  resources,
  interpolation: {
    escapeValue: false,
  },
  keySeparator: ".", // Use a period as the default key separator
  nsSeparator: ":", // Use a colon as the namespace separator
  // debug: true,
});

export default i18n;
