import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as rudderanalytics from "rudder-sdk-js";

import { Box, Button } from "@chakra-ui/react";
import Spinner from "../components/spinner";

import { useToast } from "../hooks/use-toast";
import { useInterval } from "../hooks/use-interval";
import { useFetch } from "../hooks/use-fetch";

import {
  getCustomerDetails,
  getPackages as getMondiaPackages,
  getCountryCode,
} from "../services/mondia-payment";
import {
  getCards,
  getPackages as getTapPackages,
} from "../services/tap-payment";

import { isEmpty, isNil, isUndefined } from "lodash";
import getChannel from "../utils/get-channel";
import errorCodeToTranslationKey from "../utils/error-code-to-translation-key";
import webviewChecker from "../utils/webview-checker";
import getDialCode from "../utils/get-dial-code";
import getMondiaRenderCondition from "../utils/get-mondia-render-condition";
import getTapRenderCondition from "../utils/get-tap-render-condition";

import { logEventName } from "../constants";

const isBlank = (val) => {
  if (typeof val === "string"){
    return isEmpty(val) || isNil(val)
  }else{
    return isUndefined(val) || isNil(val)
  }
};

const Home = () => {
  const [isAppReady, setIsAppReady] = React.useState(false);

  React.useEffect(() => {
    const channel = getChannel();

    if (isAppReady && channel) {
      channel?.postMessage(
        JSON.stringify({
          data: {},
          error: "",
          event: "app_ready",
        })
      );
    }
  }, [isAppReady]);

  useInterval(
    () => {
      if (window.PaymentJS) {
        // Triggers the web-app to send `app_ready` status.
        // This state changes will trigger [send_app_ready_status_effect]
        setIsAppReady(true);
      }
    },
    isAppReady ? null : 400
  );

  const initRef = React.useRef(null);

  const isWebview = webviewChecker();

  const getInitPaymentParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sid = urlParams.get("sid");
    const package_id = urlParams.get("package_id");
    const session_key = urlParams.get("session_key");
    const price = urlParams.get("price");
    const operator = urlParams.get("operator");
    const phoneNumber = urlParams.get("phoneNumber");
    const dialCode = urlParams.get("dialCode");
    const source = urlParams.get("source");
    const source_screen = urlParams.get("source_screen");
    const trial = urlParams.get("trial");
    const preferedAdsId = urlParams.get("preferedAdsId");
    const backupAdsId = urlParams.get("backupAdsId");
    const tapCouponCode = urlParams.get("couponCode");

    return {
      ...(!isBlank(sid) && { sid: String(sid) }),
      ...(!isBlank(package_id) && { package_id: String(package_id) }),
      session_key,
      price,
      operator: operator?.toLowerCase(),
      phoneNumber,
      dialCode,
      source,
      source_screen,
      trial: trial === "true" ? true : false,
      preferedAdsId,
      backupAdsId,
      tapCouponCode
    };
  };

  const [paymentParams, setPaymentParams] = React.useState(
    !isWebview ? getInitPaymentParams() : {}
  );

  React.useEffect(() => {
    if (initRef.current !== null) {
      const secretInput = initRef.current;

      secretInput.addEventListener("click", (e) => {
        const paymentParams = window.paymentParams;
        setPaymentParams(paymentParams);
      });
    }
  }, [setPaymentParams]);

  React.useEffect(() => {
    rudderanalytics.identify(paymentParams?.sid);
    rudderanalytics.page();
  }, [paymentParams?.sid]);

  const { showToast } = useToast();

  const { t } = useTranslation();

  /**
   * We use useCallback to prevent `useFetch`
   * called infinite time
   */
  const getCustDetailsCallback = React.useCallback(
    () =>
    [paymentParams.sid, paymentParams.session_key].some(isBlank)
      ? Promise.resolve([]) 
      : getCustomerDetails({
        sid: paymentParams.sid,
        session_key: paymentParams.session_key,
      }),
    [paymentParams.session_key, paymentParams.sid]
  );

  const {
    isLoading: getCustDetailsLoading,
    data: getCustDetailsData,
    error: getCustDetailsError,
  } = useFetch(getCustDetailsCallback);

  React.useEffect(() => {
    if (!isBlank(getCustDetailsError)) {
      const toastId = "toast-customer-details";

      const translationKey = errorCodeToTranslationKey(
        getCustDetailsError?.response?.data.message
      );
      const errorTitle = t(`errors:${translationKey}.title`);
      const errorDesc = t(`errors:${translationKey}.message`);

      return showToast({
        id: toastId,
        message: {
          // title: `ln: custdetails ${errorTitle}`,
          title: `${errorTitle}`,
          description: errorDesc,
        },
      });
    }
  }, [getCustDetailsError, showToast, t]);

  const isFinishGetCustDetails =
    !isBlank(getCustDetailsData) || !isBlank(getCustDetailsError);

  React.useEffect(() => {
    if (isFinishGetCustDetails) {
      const userProfile = process.env.REACT_APP_IS_VERSION_V5 === 'true' ? getCustDetailsData?.user_profile : getCustDetailsData?.data;
      rudderanalytics.track(logEventName.customerDetailsRequested, {
        sid: paymentParams?.sid,
        isWebview,
        success: !isBlank(userProfile) ? true : false,
        ...(!isBlank(getCustDetailsError?.response?.data) && {
          error: {
            code: getCustDetailsError?.response?.data?.code,
            details: getCustDetailsError?.response?.data?.details,
            error: {
              code: getCustDetailsError?.response?.data?.code,
              details: getCustDetailsError?.response?.data?.details,
              message: getCustDetailsError?.response?.data?.message,
            },
          },
        }),
      });
    }
  }, [
    getCustDetailsData?.user_profile,
    getCustDetailsData?.data,
    getCustDetailsError?.response?.data,
    isFinishGetCustDetails,
    isWebview,
    paymentParams.sid,
  ]);

  /**
   * We use useCallback to prevent `useFetch`
   * called infinite time
   */
  const getPackagesCallback = React.useCallback(
    () =>
      [paymentParams.package_id, paymentParams?.sid].some(isBlank)
        ? Promise.resolve([])
        : window.channelName?.toLowerCase() !== "tap"
        ? getMondiaPackages({ id: paymentParams.package_id })
        : getTapPackages({
            subscriptionTypeId: Number(paymentParams.package_id),
            sid: paymentParams?.sid,
            session_key: paymentParams?.session_key,
          }),
    [paymentParams.package_id, paymentParams?.sid, paymentParams?.session_key]
  );

  const {
    isLoading: getPackagesLoading,
    data: getPackagesData,
    error: getPackagesError,
  } = useFetch(getPackagesCallback);

  React.useEffect(() => {
    const toastId = "toast-package-details";

    /**
     * Show error message if normal flow didn't provide package
     * information
     */
    if (
      isBlank(paymentParams?.operator) &&
      !isBlank(paymentParams?.package_id) &&
      !isBlank(getPackagesData?.data)
    ) {
      if (isBlank(getPackagesData?.data?.id)) {
        const translationKey = errorCodeToTranslationKey(
          "INVALID_SUBSCRIPTION_PLAN"
        );
        const errorTitle = t(`errors:${translationKey}.title`);
        const errorDesc = t(`errors:${translationKey}.message`);

        return showToast({
          id: toastId,
          message: {
            // title: `getPackageData ${errorTitle}`,
            title: `${errorTitle}`,
            description: errorDesc,
          },
        });
      }
    }
    if (!isBlank(getPackagesError)) {
      const translationKey = errorCodeToTranslationKey(
        getPackagesError?.response?.data.message
      );
      const errorTitle = t(`errors:${translationKey}.title`);
      const errorDesc = t(`errors:${translationKey}.message`);

      return showToast({
        id: toastId,
        message: {
          // title: `getpackageserror ${errorTitle}`,
          title: `${errorTitle}`,
          description: errorDesc,
        },
      });
    }
  }, [
    getPackagesData?.data,
    getPackagesError,
    paymentParams?.operator,
    paymentParams?.package_id,
    showToast,
    t,
  ]);

  const isFinishGetPackages =
    !isBlank(getPackagesData) || !isBlank(getPackagesError);

  React.useEffect(() => {
    if (isFinishGetPackages) {
      rudderanalytics.track(logEventName.packagesRequested, {
        sid: paymentParams?.sid,
        subscriptionTypeId: paymentParams?.package_id,
        isWebview,
        success: !isBlank(getPackagesData?.data) ? true : false,
        ...(!isBlank(getPackagesError?.response?.data) && {
          error: {
            code: getPackagesError?.response?.data?.code,
            details: getPackagesError?.response?.data?.details,
            error: {
              code: getPackagesError?.response?.data?.code,
              details: getPackagesError?.response?.data?.details,
              message: getPackagesError?.response?.data?.message,
            },
          },
        }),
      });
    }
  }, [
    getPackagesData?.data,
    getPackagesError?.response?.data,
    isFinishGetPackages,
    isWebview,
    paymentParams?.package_id,
    paymentParams?.sid,
  ]);

  /**
   * We use useCallback to prevent `useFetch`
   * called infinite time
   */
  const getCardsCallback = React.useCallback(
    () => 
    [paymentParams.sid, paymentParams.session_key].some(isBlank)
      ? Promise.resolve([]) 
      : getCards({ sid: paymentParams.sid, session_key: paymentParams.session_key }),
    [paymentParams.sid, paymentParams.session_key]
  );

  const {
    isLoading: getCardsLoading,
    data: getCardsData,
    error: getCardsError,
  } = useFetch(getCardsCallback);

  const isFinishGetCards = !isBlank(getCardsData) || !isBlank(getCardsError);

  React.useEffect(() => {
    if (isFinishGetCards) {
      rudderanalytics.track(logEventName.cardsRequested, {
        sid: paymentParams.sid,
        isWebview,
        success: !isBlank(getCardsData?.data) ? true : false,
        ...(!isBlank(getCardsError?.response?.data) && {
          error: {
            code: getCardsError?.response?.data?.code,
            details: getCardsError?.response?.data?.details,
            error: {
              code: getCardsError?.response?.data?.code,
              details: getCardsError?.response?.data?.details,
              message: getCardsError?.response?.data?.message,
            },
          },
        }),
      });
    }
  }, [
    getCardsData,
    getCardsError,
    isFinishGetCards,
    isWebview,
    paymentParams?.sid,
  ]);

  /**
   * We use useCallback to prevent `useFetch`
   * called infinite time
   */
  const getCountryCodeCallback = React.useCallback(() => getCountryCode(), []);

  const {
    isLoading: getCountryCodeLoading,
    data: getCountryCodeData,
    error: getCountryCodeError,
  } = useFetch(getCountryCodeCallback);

  const isFinishGetCountryCode =
    !isBlank(getCountryCodeData) || !isBlank(getCountryCodeError);

  React.useEffect(() => {
    if (isFinishGetCountryCode) {
      rudderanalytics.track(logEventName.countryCodeRequested, {
        sid: paymentParams.sid,
        isWebview,
        success: !isBlank(getCountryCodeData?.data) ? true : false,
        ...(!isBlank(getCountryCodeError?.response?.data) && {
          error: {
            code: getCountryCodeError?.response?.data?.code,
            details: getCountryCodeError?.response?.data?.details,
            error: {
              code: getCountryCodeError?.response?.data?.code,
              details: getCountryCodeError?.response?.data?.details,
              message: getCountryCodeError?.response?.data?.message,
            },
          },
        }),
      });
    }
  }, [
    getCountryCodeData?.data,
    getCountryCodeError?.response?.data,
    isFinishGetCountryCode,
    isWebview,
    paymentParams.sid,
  ]);

  const tapLoading = getCardsLoading;

  const multiProviderLoading =
    getCustDetailsLoading && getPackagesLoading && getCountryCodeLoading;

  const isLoading =
    window.channelName?.toLowerCase() === "tap"
      ? tapLoading || multiProviderLoading
      : multiProviderLoading;

  const navigate = useNavigate();

  /**
   * We'll configure all required processes before navigating to
   * each payment in this effect
   */
  React.useEffect(() => {
    /**
     * Tap payment requirements:
     * 1. channelName: "Tap"
     * 2. price from client and price from our server match
     * 3. customer details from client and customer details from our
     * server matc
     */
    if (
      getTapRenderCondition({
        customerDetails: process.env.REACT_APP_IS_VERSION_V5 === 'true' ? getCustDetailsData?.user_profile: getCustDetailsData?.data,
        packageDetails: getPackagesData?.data,
        channelName: window.channelName,
        packageId: paymentParams.package_id,
      }) &&
      !isLoading
    ) {
      navigate(
        !paymentParams.trial || paymentParams.package_id
          ? "tap-payment/subscribe"
          : "tap-payment/choose-package",
        {
          state: {
            sid: process.env.REACT_APP_IS_VERSION_V5 === 'true' ? getCustDetailsData?.user_profile?.sid : getCustDetailsData?.data?.sid,
            sessionKey: paymentParams.session_key,
            ...(!isBlank(getPackagesData?.data) && {
              packageId: getPackagesData?.data?.id,
            }),
            cards: !isBlank(getCardsData?.data) ? getCardsData?.data : [],
            source_screen: paymentParams.source_screen,
            source: paymentParams.source,
            trial:
              !paymentParams.trial || !paymentParams.package_id ? false : true,
            country: getCountryCodeData?.data?.country_code,
            provider: window.channelName?.toLowerCase(),
            preferedAdsId: paymentParams?.preferedAdsId,
            backupAdsId: paymentParams?.backupAdsId,
            tapCouponCode: paymentParams?.tapCouponCode
          },
        }
      );
    }
    if (
      getMondiaRenderCondition({
        customerDetails: process.env.REACT_APP_IS_VERSION_V5 === 'true' ? getCustDetailsData?.user_profile: getCustDetailsData?.data,
        countryCode: getCountryCodeData?.data?.country_code,
        packageDetails: getPackagesData?.data,
        operator: paymentParams.operator,
        channelName: window.channelName,
      })
    ) {
      navigate("mondia-payment/input-number", {
        state: {
          sid: process.env.REACT_APP_IS_VERSION_V5 === 'true' ? getCustDetailsData?.user_profile?.sid : getCustDetailsData?.data?.sid,
          sessionKey: paymentParams.session_key,
          ...(!isBlank(getPackagesData?.data) && {
            packageId: getPackagesData?.data?.id,
          }),
          ...(!isBlank(paymentParams.operator) && {
            operator: paymentParams.operator,
          }),
          ...(!isBlank(paymentParams.operator) && {
            phoneNumber: paymentParams.phoneNumber,
          }),
          ...(!isBlank(paymentParams.dialCode)
            ? { dialCode: paymentParams.dialCode }
            : {
                dialCode: getDialCode(getCountryCodeData?.data?.country_code),
              }),
          source: paymentParams.source,
          provider: window.channelName?.toLowerCase(),
          preferedAdsId: paymentParams.preferedAdsId,
          backupAdsId: paymentParams.backupAdsId,
        },
      });
    }
  }, [
    paymentParams,
    navigate,
    getCustDetailsData,
    getPackagesData,
    getCountryCodeData,
    getCardsData?.data,
    isLoading,
  ]);

  return (
    <Box>
      <Button id="init" ref={initRef} hidden></Button>
      {isLoading && <Spinner />}
    </Box>
  );
};

export default Home;
