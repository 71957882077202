const errorCodeToTranslationKey = (code) => {
  switch (code) {
    case "INVALID_MSISDN":
      return "invalid-msisdn";
    case "MSISDN_NOT_FOUND":
      return "invalid-msisdn";
    case "AMOUNT_NOT_VALID":
      return "invalid-charge-amount";
    case "INCORRECT_OTP":
      return "incorrect-otp";
    case "BAD_REQUEST":
      return "bad-request";
    case "INSUFFICIENT_FUNDS":
      return "insufficient-funds";
    case "PURCHASE_REQUEST_NOT_VALID":
      return "purchase-request-not-valid";
    case "PAYMENT_TEMPORARILY_BLOCKED":
      return "payment-temporarily-blocked";
    case "INVALID_SUBSCRIPTION_PLAN":
      return "invalid-subscription-plan";
    case "MISMATCH_PRICE":
      return "price-changed";
    case "USER_NOT_FOUND":
      return "user-not-found";
    case "USER_ALREADY_SUBSCRIBED":
    case "USER_IS_SUBSCRIBER":
      return "user-is-subscriber";
    case "Subscription has been redeemed": {
      return "subscription-has-been-redeemed";
    }
    case "TOO_MANY_REQUEST": {
      return "too-many-request";
    }
    case "VALIDATION_ERROR": {
      return "validation-error";
    }
    case "EXPIRED_OTP": {
      return "expired-otp";
    }
    case "USER_IS_BLOCKED": {
      return "user-is-blocked";
    }
    case "USER_IS_BLACKLISTED": {
      return "user-is-blacklisted";
    }
    case "PIN_LIMIT_EXCEEDED": {
      return "pin-limit-exceeded";
    }
    case "USER_NOT_ACTIVE": {
      return "user-not-active";
    }
    default:
      return "bad-request";
  }
};

export default errorCodeToTranslationKey;
