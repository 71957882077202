const getChannel = (channelName) => {
  const channel = window.channelName || channelName;

  if (!channel) {
    console.error("Channel not found!");
  }

  const isWindowWebkitAvailable =
    typeof window !== "undefined" && typeof window.webkit !== "undefined";

  if (isWindowWebkitAvailable) {
    const wk = window.webkit;

    return wk.messageHandlers[channel];
  }

  return window[channel];
};

export default getChannel;
